@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1140px;
  margin: 1rem auto;
}

.header img {
  height: 120px;
}

.header .logo {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.header .logo .title {
  font-size: 2.5rem;
  color: #018030;
}

.header .logo .title_text {
  text-align: left;
  font-size: 1.125rem;
  font-weight: 600;
}

.header .logo .title_text .nepali {
  letter-spacing: 3px;
  color: #018030;
}

.header .logo .title_text .underline {
  background-color: #018030;
  height: 2.4px;
  width: 95%;
}

.header .logo .title_text .english {
  letter-spacing: -0.5px;
  color: #e32e31;
}

.header .connect {
  display: flex;
  gap: 1rem;
  text-align: left;
}

.header .connect_link {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.header .connect_link .icon {
  font-size: 2rem;
  color: #002b71;
}

.header .connect_link .text {
  border-left: 1px dashed rgba(58, 55, 55, 0.3);
  padding-left: 8px;
}

.header .connect_link .text h5 {
  color: #000;
}

.header .connect_link .text p {
  color: #3a3737;
  font-weight: 300;
}

.header .connect_link .text .active {
  color: black;
}

.header .connect_link .text .disabled {
  opacity: 0.7;
  word-break: break-all;
}

.header .connect_link .text .isopen-closed {
  color: #ed4611 !important;
  opacity: 1 !important;
}

.header .connect_link .text .isopen-open {
  color: #018030 !important;
  opacity: 1 !important;
}

.navbar {
  background: #018030;
  border-bottom: 3px solid #e32e31;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar_component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
}

.navbar_component ul {
  display: flex;
  align-items: center;
  padding: 0;
}

.navbar_component ul li {
  list-style-type: none;
  position: relative;
}

.navbar_component ul li .icon {
  font-size: 1rem;
}

.navbar_component ul li a {
  background: #fff;
  padding: 0.4rem 1rem;
  margin: 0 0.2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  color: #000;
  font-size: 0.8rem;
  font-weight: 500;
  transition: all 0.3s linear;
}

.navbar_component ul li a .sublinks {
  display: none;
}

.navbar_component ul li a:hover {
  background: #ed4611;
  color: #fff;
  position: relative;
}

.navbar_component ul li a:hover .sublinks {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  z-index: 9999;
  background-color: #018030;
  padding-bottom: 0;
  border-radius: 5px !important;
  transform: translateX(-10px);
  overflow: hidden;
}

.navbar_component ul li a:hover .sublinks_list {
  width: 100%;
  padding: 0 !important;
  text-align: center;
}

.navbar_component ul li a:hover .sublinks_list .sublinks_item {
  display: flex;
  color: #fff;
  text-transform: capitalize;
  background: #018030;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.6rem 0.8rem;
  min-width: max-content;
  width: 100%;
  font-weight: normal;
}

.navbar_component ul li a:hover .sublinks_list .sublinks_item:hover {
  background: #ed4611;
}

.navbar_component ul li .active_link {
  background: #ed4611;
  color: #fff;
}

.navbar_component ul {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.navbar_component ul li {
  list-style-type: none;
  position: relative;
}

.navbar_component ul li .icon {
  font-size: 1rem;
}

.navbar_component ul li a {
  background: #fff;
  padding: 0.4rem 1rem;
  margin: 0 0.2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  color: #000;
  font-size: 0.8rem;
  font-weight: 500;
  transition: all 0.3s linear;
}

.navbar_component button {
  border: none;
  background: #fff;
  padding: 0.4rem 1rem;
  margin: 0 0.2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  color: #000;
  font-size: 0.8rem;
  font-weight: 500;
  transition: all 0.3s linear;
}

.navbar_component button .sublinks {
  display: none;
}

.navbar_component button:hover {
  background: #ed4611;
  color: #fff;
  position: relative;
}

.navbar_component button:hover .sublinks {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  position: absolute;
  top: 100%;
  z-index: 9999;
  background-color: #018030;
  border-radius: 5px !important;
  transform: translateX(-10px);
  overflow: hidden;
}

.navbar_component button:hover .sublinks_list {
  width: 100%;
  padding: 0 !important;
  text-align: center;
}

.navbar_component button:hover .sublinks_list .sublinks_item {
  display: flex;
  color: #fff;
  text-transform: capitalize;
  background: #018030;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0.6rem 0.8rem;
  min-width: max-content;
  width: 100%;
  font-weight: normal;
}

.navbar_component button:hover .sublinks_list .sublinks_item:hover {
  background: #ed4611;
}

.news {
  max-width: 1140px;
  margin: 1rem auto;
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  z-index: -99;
}

.news h2 {
  text-transform: capitalize;
  font-size: 1.1rem;
  padding: 0.5rem;
  font-weight: 400;
  background: #e32e31;
  color: #fff;
  border-radius: 5px 0 0 5px;
}

.news .wrapper {
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 0px 5px 5px 0;
  display: flex;
}

.news .wrapper .news_title {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transform: translateX(100%);
  animation: scrolling 50s linear infinite;
}

.news .wrapper .news_title:hover {
  animation-play-state: paused;
}

.news .wrapper .news_title li {
  position: relative;
  width: max-content !important;
  list-style-type: circle;
}

.news .wrapper .news_title li a {
  text-decoration: none;
  padding: 0 5px;
  color: #0c4fbb;
  font-size: 14px;
}

.news .wrapper .news_title li a:hover {
  color: #018030;
}

@keyframes scrolling {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.main {
  background: #002b71;
  padding: 1rem 0 !important;
}

.slider {
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  min-height: 550px;
  overflow: hidden;
  border-radius: 10px;
  border: 5px solid rgba(255, 255, 255, 0.8);
}

.slider .images .icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.6);
  padding: 0 1rem;
}

.slider .images article {
  position: absolute;
  width: 100%;
  height: 100%;
  /* opacity: 0; */
  transition: var(--transition);
}

.slider .images article .my-image {
  width: 100%;
}

.slider .images article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

.slider .images article.lastSlide {
  transform: translateX(-100%);
}

.slider .images article.nextSlide {
  transform: translateX(100%);
}

.popup {
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  z-index: 99999999999 !important;
}

.popup_content {
  max-width: 600px;
  overflow-y: auto;
  margin: auto;
  border: 2px solid #3e3e3e;
  background: #fdfcfc;
  padding: 0.5rem;
  position: relative;
  top: 4rem;
}

.popup_content .close {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;
}

.popup_content .message {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0 10rem 0;
  overflow-y: auto;
}

.popup_content .message article p {
  margin-top: 2rem;
  text-align: left;
  font-weight: 500;
  color: yellow;
  background-color: #3e3e3e;
  padding: 1rem;
}

.popup_content .message article img {
  width: 100%;
}

.popup_content hr {
  display: block;
}

.about {
  padding: 5rem 0;
  max-width: 1140px;
  margin: 0 auto;
}

.about h2 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.5rem;
  color: #ed4611;
}

.about .slogan {
  color: #018030;
  font-size: 1rem;
}

.about .collect {
  position: relative;
  align-items: center;
}

.about .collect .underline {
  border: 2px solid red;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ed4611;
}

.about .collect .underline .welcomeicon {
  margin: 2rem auto;
  font-size: 2.5rem;
  color: #fff;
}

.about .collect .underline .line {
  width: 70%;
  height: 5px;
  margin: 0 auto;
  background: #ed4611;
}

.about .collect hr {
  position: relative;
  bottom: 50px;
  z-index: -999;
  color: #ed4611;
  background: #ed4611;
  border: 0.2px solid #ed4611;
  width: 60%;
  margin: 0 auto;
}

.about .content {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 60% 1fr;
  gap: 5rem;
}

.about .content_text {
  text-align: left;
  letter-spacing: 1px;
  line-height: 1.5rem;
}

.about .content_text button {
  margin: 2rem 0;
  border: none;
  background: transparent;
}

.about .content_text button a {
  padding: 0.8rem 1.2rem;
  color: #ed4611;
  border-radius: 10px;
  outline: 1px solid #ed4611;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
}

.about .content_text button a .icon {
  font-size: 1rem;
}

.about .content_text button a:hover {
  background: #ed4611;
  color: #fff;
}

.about .content img {
  border-radius: 5px;
  width: 100%;
  object-fit: cover;
}

.wrapper {
  background: #ed4611;
}

.wrapper .missionvision {
  max-width: 1140px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 3rem 0;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.wrapper .missionvision .mission_vision {
  box-shadow: 0 0 100px rgba(58, 55, 55, 0.5);
  background: #ed4611;
  border-radius: 10px;
  padding: 2rem;
  transition: all 0.5s ease-in-out;
  border: 0.1px solid rgba(255, 255, 255, 0.5);
}

.wrapper .missionvision .mission_vision .icon_ {
  font-size: 2rem;
  color: #fff;
  border: 1px solid #fff !important;
  width: 120px;
  height: 90px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.wrapper .missionvision .mission_vision h2 {
  font-weight: 300;
  font-size: 2.125rem;
  color: #fff;
  margin: 1rem;
}

.wrapper .missionvision .mission_vision p {
  color: #fff;
  line-height: 1.5rem;
  letter-spacing: 1px;
}

.wrapper .missionvision .mission_vision button {
  margin: 1rem 0;
  border: none;
  border-radius: 10px;
}

.wrapper .missionvision .mission_vision button a {
  padding: 0.6rem 1rem;
  color: #ed4611;
  border-radius: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  border: 1px solid #fff !important;
  background: #ed4611;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.wrapper .missionvision .mission_vision button a .icon {
  font-size: 1rem;
  color: #fff;
}

.wrapper .missionvision .mission_vision:hover {
  background: #ef5929;
  transform: scale(1.04);
}

.wrapper .missionvision .mission_vision:hover .icon_ {
  color: #ed4611;
  background: #fff;
  border-radius: 20px;
}

.wrapper .missionvision .mission_vision:hover button a {
  background: #fff !important;
  color: #ed4611;
}

.wrapper .missionvision .mission_vision:hover button a .icon {
  font-size: 1rem;
  color: #ed4611;
}

.services {
  background: #002b71;
}

.services .service {
  padding: 5rem 0;
  max-width: 1140px;
  margin: 0 auto;
}

.services .service h2 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.5rem;
  color: #fff;
}

.services .service .slogan {
  color: #fff;
  font-size: 1rem;
}

.services .service .collect {
  position: relative;
  align-items: center;
}

.services .service .collect .underline {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 99999;
}

.services .service .collect .underline .welcomeicon {
  margin: 2rem auto;
  font-size: 2.5rem;
  color: #ed4611;
  z-index: 99999;
}

.services .service .collect .underline .line {
  width: 70%;
  height: 5px;
  margin: 0 auto;
  background: #ed4611;
}

.services .service .collect hr {
  position: relative;
  bottom: 50px;
  color: #fff;
  background: #fff;
  border: 0.2px solid #fff;
  width: 60%;
  margin: 0 auto;
}

.services .service .content {
  margin-top: 3rem;
  width: 100%;
}

.services .service .content_text {
  width: 100%;
  letter-spacing: 1px;
  line-height: 1.5rem;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  text-align: center;
}

.services .service .content_text article {
  box-shadow: 0px 0px 20px #ed4611;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  padding: 1rem;
  transition: all 0.5s ease-in-out;
  background-color: white;
}

.services .service .content_text article .icon_ {
  font-size: 4rem;
  color: #002b71;
}

.services .service .content_text article h4 {
  color: #002b71;
  transition: all 0.3s linear;
  font-size: 1.5rem;
  margin: 1rem 0;
  font-weight: normal;
}

.services .service .content_text article p {
  text-align: center;
  color: #002b71;
  letter-spacing: 1px !important;
}

.services .service .content_text article button {
  margin: 2rem 0;
  border: none;
  background: transparent;
}

.services .service .content_text article button a {
  padding: 0.6rem 1rem;
  color: #ed4611;
  border-radius: 10px;
  outline: 1px solid #ed4611;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 0.8rem;
}

.services .service .content_text article button a .icon {
  font-size: 1rem;
}

.services .service .content_text article button a:hover {
  background: #ed4611;
  color: #fff;
}

.services .service .content_text article:hover {
  transform: scale(1.04);
}

.services .service .content_text article:hover .icon_ {
  color: #ed4611;
}

.services .service .content_text article:hover h4 {
  color: #ed4611;
}

.partner-wrapper {
  background: #ed4611;
}

.partner-wrapper .partners {
  padding: 5rem 0;
  max-width: 1140px;
  margin: 0 auto;
}

.partner-wrapper .partners h2 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.5rem;
  color: #fff;
}

.partner-wrapper .partners .slogan {
  color: #fff;
  font-size: 1rem;
}

.partner-wrapper .partners .collect {
  position: relative;
  align-items: center;
}

.partner-wrapper .partners .collect .underline {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 99999;
}

.partner-wrapper .partners .collect .underline .welcomeicon {
  margin: 2rem auto;
  font-size: 2.5rem;
  color: #ed4611;
  z-index: 99999;
}

.partner-wrapper .partners .collect .underline .line {
  width: 70%;
  height: 5px;
  margin: 0 auto;
  background: #ed4611;
}

.partner-wrapper .partners .collect hr {
  position: relative;
  bottom: 50px;
  color: #fff;
  background: #fff;
  border: 0.2px solid #fff;
  width: 60%;
  margin: 0 auto;
}

.partner-wrapper .partners .partner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-wrapper .partners .partner article {
  display: flex;
}

.partner-wrapper .partners .partner article img {
  height: 150px;
  background-color: white;
  padding: 0.2rem;
  border-radius: 10px;
  margin: 0 1rem;
}

.events-wrapper {
  background: #fff;
}

.events-wrapper .events {
  padding: 5rem 0;
  max-width: 1140px;
  margin: 0 auto;
}

.events-wrapper .events h2 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.5rem;
  color: #ed4611;
}

.events-wrapper .events .slogan {
  color: #018030;
  font-size: 1rem;
}

.events-wrapper .events .collect {
  position: relative;
  align-items: center;
}

.events-wrapper .events .collect .underline {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ed4611;
  z-index: 99999;
}

.events-wrapper .events .collect .underline .welcomeicon {
  margin: 2rem auto;
  font-size: 2.5rem;
  color: #fff;
  z-index: 99999;
}

.events-wrapper .events .collect .underline .line {
  width: 70%;
  height: 5px;
  margin: 0 auto;
  background: #ed4611;
}

.events-wrapper .events .collect hr {
  position: relative;
  bottom: 50px;
  color: #ed4611;
  background: #ed4611;
  border: 0.2px solid #ed4611;
  width: 60%;
  margin: 0 auto;
}

.events-wrapper .events .event {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}

.events-wrapper .events .event article {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ed4611;
  border-radius: 10px;
  box-shadow: 0 0 10px #ed4611;
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
}

.events-wrapper .events .event article img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-color: white;
  padding: 0.2rem;
  border-radius: 10px;
  margin: 0 1rem;
  transition: all 0.3s ease-in-out;
}

.events-wrapper .events .event article h4 {
  color: #018030;
  font-weight: normal;
  margin: 0.3rem;
  font-size: 0.9rem;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
}

.events-wrapper .events .event article .date {
  font-size: 0.8rem;
  margin: 0.3rem 0;
}

.events-wrapper .events .event article p {
  text-align: justify;
  font-weight: 300;
  font-size: 0.9rem;
}

.events-wrapper .events .event article button {
  margin: 2rem 0;
  border: none;
  background: transparent;
  width: max-content;
}

.events-wrapper .events .event article button a {
  padding: 0.6rem 1rem;
  color: #ed4611;
  border-radius: 10px;
  outline: 1px solid #ed4611;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 0.7rem;
}

.events-wrapper .events .event article button a .icon {
  font-size: 1rem;
}

.events-wrapper .events .event article button a:hover {
  background: #ed4611;
  color: #fff;
}

.events-wrapper .events .event article:hover {
  transform: scale(1.05);
}

.events-wrapper .events .event article:hover h4 {
  transform: scale(1.1);
}

.contact-wrapper {
  background: linear-gradient(to right, rgba(0, 43, 113, 0.7), rgba(0, 43, 113, 0.7)), url("../images/img-1.png");
  padding: 4rem 0;
}

.contact-wrapper .contact {
  display: flex;
  flex-direction: column;
}

.contact-wrapper .contact .header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact-wrapper .contact .header .title {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
}

.contact-wrapper .contact .header .collect {
  position: relative;
  align-items: center;
  width: 100%;
}

.contact-wrapper .contact .header .collect .underline {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 99999;
}

.contact-wrapper .contact .header .collect .underline .welcomeicon {
  margin: 2rem auto;
  font-size: 2.5rem;
  color: #ed4611;
  z-index: 99999;
}

.contact-wrapper .contact .header .collect hr {
  position: relative;
  bottom: 50px;
  color: #fff;
  background: #fff;
  border: 0.5px solid #fff;
  width: 60%;
  margin: 0 auto;
}

.contact-wrapper .contact .main {
  max-width: 1140px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-content: space-between;
  background: none;
  width: 100%;
  position: relative;
}

.contact-wrapper .contact .main .content {
  position: absolute;
  right: 0;
  top: 1rem;
  padding: 1rem;
  color: #fff;
  background: #ed4611;
  border-radius: 10px;
}

.contact-wrapper .contact .main .content h3 {
  font-weight: 400;
  margin: 0.7rem 0;
  letter-spacing: 1px;
}

.contact-wrapper .contact .main .content p {
  opacity: 0.7;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.contact-wrapper .contact .main .content p a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  text-decoration: none;
}

.contact-wrapper .contact .main .content p .icon_ {
  opacity: 1 !important;
  font-size: 1.5rem;
}

.contact-wrapper .contact .main .content .social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 2.5rem;
  margin: 1rem 0;
}

.contact-wrapper .contact .main .content .social-links a {
  text-decoration: none;
  color: #fff;
}

.contact-wrapper .contact .main .content .social-links a .icon {
  border: 1px dotted #fff;
  padding: 5px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.contact-wrapper .contact .main .content .social-links a .icon:hover {
  color: #ed4611;
  background-color: #fff;
}

.contact-wrapper .contact .main .map {
  border-radius: 20px;
  overflow: hidden;
}

.footer-wrapper {
  background: #018030;
  padding: 3rem 0;
  position: relative;
}

.footer-wrapper .footer {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1.5rem;
  color: #fff;
}

.footer-wrapper .footer .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.footer-wrapper .footer .info .title {
  color: #d9fa53;
  text-transform: uppercase;
}

.footer-wrapper .footer .info img {
  max-width: 120px;
}

.footer-wrapper .footer .info p {
  display: flex;
  gap: 0.5rem;
}

.footer-wrapper .footer .aboutus .title,
.footer-wrapper .footer .downloads .title,
.footer-wrapper .footer .service .title {
  padding-bottom: 1rem;
  border-bottom: 2px solid #ed4611;
  color: #d9fa53;
}

.footer-wrapper .footer .aboutus li,
.footer-wrapper .footer .downloads li,
.footer-wrapper .footer .service li {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem auto;
}

.footer-wrapper .footer .aboutus li a,
.footer-wrapper .footer .downloads li a,
.footer-wrapper .footer .service li a {
  text-decoration: none;
  color: #fff;
}

.footer-wrapper .footer .aboutus li a:hover,
.footer-wrapper .footer .downloads li a:hover,
.footer-wrapper .footer .service li a:hover {
  color: #d9fa53;
  transform: scale(1.1);
}

.footer-wrapper .footer .aboutus .title {
  text-align: left;
}

.copyright {
  position: relative;
  bottom: 0;
  width: 100%;
  background: #262626;
  padding: 1rem 0;
}

.copyright p {
  color: #fff;
  font-size: 1rem;
  opacity: 0.8;
  font-weight: 300;
  margin: 1rem 0;
}

.copyright span {
  font-size: 1rem;
  color: #fff;
  opacity: 0.7;
  font-weight: 400;
}

.eventspage-wrapper {
  background: #002b71;
  padding-bottom: 3rem;
}

.eventspage-wrapper h2 {
  color: #fff;
  text-align: center;
  padding-top: 3rem;
  font-size: 2.5rem;
  font-weight: normal;
}

.eventspage-wrapper .eventspage {
  max-width: 1140px;
  margin: 3rem auto 0 auto;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #ed4611;
}

.eventspage-wrapper .eventspage ul {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 3rem;
  gap: 1rem;
}

.eventspage-wrapper .eventspage ul .title_single {
  font-size: 2rem;
  font-weight: normal;
  color: #018030;
}

.eventspage-wrapper .eventspage ul .date_single {
  font-size: 0.9rem;
  opacity: 0.8;
}

.eventspage-wrapper .eventspage ul .description_single {
  margin: 1rem 0;
  line-height: 1.5rem;
  letter-spacing: 1px;
}

.eventspage-wrapper .eventspage ul .image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 195px);
  gap: 1rem;
  margin-top: 5rem;
}

.eventspage-wrapper .eventspage ul .image-grid img {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  object-fit: contain;
  box-shadow: 0 0 5px #ed4611;
}

.eventspage-wrapper .eventspage ul li {
  transition: all 0.3s linear;
  display: grid;
  grid-template-columns: 16rem 1fr;
  border: 1px solid rgba(58, 55, 55, 0.2);
  border-radius: 10px;
}

.eventspage-wrapper .eventspage ul li:hover {
  background: rgba(58, 55, 55, 0.2);
  transform: scale(1.01);
}

.eventspage-wrapper .eventspage ul li img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}

.eventspage-wrapper .eventspage ul li .content {
  text-align: left;
  padding: 0 1.5rem;
}

.eventspage-wrapper .eventspage ul li .content .title {
  margin: 1rem 0;
  font-size: 1.3rem;
  font-weight: normal;
  color: #002b71;
}

.eventspage-wrapper .eventspage ul li .content .date {
  font-size: 0.8rem;
  color: #3a3737;
}

.eventspage-wrapper .eventspage ul li .content .date span {
  color: #018030;
}

.eventspage-wrapper .eventspage ul li .content p {
  margin: 0.5rem;
  letter-spacing: 1.5px;
}

.eventspage-wrapper .eventspage ul li .content button {
  margin: 1rem 0;
  border: none;
  background: transparent;
}

.eventspage-wrapper .eventspage ul li .content button a {
  padding: 0.6rem 1rem;
  color: #ed4611;
  border-radius: 10px;
  outline: 1px solid #ed4611;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 0.8rem;
}

.eventspage-wrapper .eventspage ul li .content button a .icon {
  font-size: 1rem;
}

.eventspage-wrapper .eventspage ul li .content button a:hover {
  background: #ed4611;
  color: #fff;
}

/* single events page scss */
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #018030;
  color: white;
}

.blink_me {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blink_title .new {
  color: #ed4611;
  font-size: 0.8rem;
  margin-right: 1rem;
}

.profile {
  padding: 2rem 1rem;
}

.profile h4 {
  font-size: 2rem;
  font-weight: normal;
  margin: 1rem 0 2rem 0;
}

.profile .profile-content p {
  padding: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.7px;
  word-spacing: 2px;
}

.vision {
  padding: 2rem 1rem;
}

.vision h4 {
  font-size: 2rem;
  font-weight: normal;
  margin: 1rem 0 2rem 0;
}

.vision .vision-content p {
  padding: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.7px;
  word-spacing: 2px;
}

.vision .vision-content .icon {
  color: #ed4611;
}

.boardmembers-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.boardmembers-content .samiti {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px dashed #ed4611;
  padding: 2rem;
  gap: 1rem;
}

.boardmembers-content .samiti li {
  list-style-type: none;
  margin: 1rem auto;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.boardmembers-content .samiti li img {
  margin: 0.5rem auto;
  width: 200px;
  height: 250px;
  border-radius: 10px;
}

.boardmembers-content .samiti h3 {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: normal;
  color: #ed4611;
}

.boardmembers-content .samiti .members {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.emicalculator {
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
}

.emicalculator .content {
  width: 1140px;
  margin: 3rem auto;
}

.emicalculator .content h2 {
  font-size: 2rem;
  font-weight: 400;
  color: #ed4611;
  text-transform: uppercase;
}

.emicalculator .content p {
  color: #018030;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.emicalculator .content .fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}

.emicalculator .content .fields .cal-area {
  margin: 3rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
}

.emicalculator .content .fields .cal-area .inputfield {
  width: 100%;
  display: grid;
  grid-template-columns: 8rem 1fr;
  align-items: center;
  gap: 1rem;
}

.emicalculator .content .fields .cal-area .inputfield label {
  font-size: 0.9rem;
  color: #000;
  text-align: left;
  text-transform: uppercase;
}

.emicalculator .content .fields .cal-area .inputfield input {
  width: 100%;
  padding: 0.5rem 0.8rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid grey;
}

.emicalculator .content .fields .cal-area .inputfield input:focus {
  box-shadow: 0 0 7px #6ad8f7;
}

.emicalculator .content .fields .cal-area .calculate {
  border-radius: 15px;
  border: 1px solid #ed4611;
  display: flex;
  gap: 5px;
  margin: 0 auto !important;
  padding: 0.7rem 1rem;
}

.emicalculator .content .fields .cal-area .calculate .icon {
  color: #ed4611;
}

.emicalculator .content .fields .cal-area .calculate button {
  font-weight: 400;
  font-size: 0.9rem;
  text-transform: uppercase;
  background: transparent;
  border: none;
  color: #ed4611;
}

.emicalculator .content .fields .pie {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 1141px) {
  .App .header {
    padding: 1rem;
  }
}

@media screen and (max-width: 1100px) {
  .App .header {
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
  }
  .App .header .connect {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 626px) {
  .App .header {
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
  }
  .App .header .logo {
    flex-direction: column;
  }
}

@media screen and (max-width: 311px) {
  .App .header .logo {
    flex-direction: column;
  }
  .App .header .logo .nepali {
    letter-spacing: 2px;
    text-align: center;
  }
}

/* end::header */
/* ========================== */
/* ========================== */
.bars {
  color: #fff;
  font-size: 1.5rem;
  display: none;
}

@media screen and (max-width: 1141px) {
  .App .navbar {
    padding: 0 1rem;
  }
  .App .navbar_component {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1007px) {
  .App .navbar_component {
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
  }
  .App .navbar_component .menu {
    width: 100%;
    padding: 0.5rem 0;
    justify-content: center;
  }
  .App .navbar_component ul {
    padding: 0.5rem 0;
  }
}

@media screen and (max-width: 895px) {
  .App .navbar {
    position: relative;
  }
  .App .navbar_component {
    justify-content: space-between !important;
    align-items: center;
    position: relative;
    width: 100%;
  }
  .App .navbar_component .bars {
    display: block;
    font-size: 1.7rem;
    transition: all 0.3s ease-in-out;
  }
  .App .navbar_component .menu {
    flex-direction: column;
    position: absolute;
    top: 100%;
    background: #fff;
    transition: all 0.4s ease-in-out;
    padding: 0;
  }
  .App .navbar_component .menu li {
    width: 100%;
  }
  .App .navbar_component .menu li a {
    position: relative;
    left: 0%;
    margin: 0;
    border-radius: 0 !important;
    padding: 0.8rem 1.5rem;
    width: 100%;
    display: flex;
  }
  .App .navbar_component .menu li a .icon-last {
    position: absolute;
    right: 1rem;
    display: block;
  }
  .App .navbar_component .menu li a:hover .sublinks {
    right: 0%;
    width: 50%;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 1141px) {
  .news {
    margin: 1rem 1rem;
  }
}

@media screen and (max-width: 420px) {
  .news {
    display: none;
  }
}

@media screen and (max-width: 828px) {
  .slider {
    min-height: 400px !important;
  }
}

@media screen and (max-width: 599px) {
  .slider {
    min-height: 300px !important;
  }
}

@media screen and (max-width: 447px) {
  .slider {
    min-height: 200px !important;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 1200px) {
  .about {
    padding: 5rem 1rem !important;
  }
}

@media screen and (max-width: 994px) {
  .content {
    display: flex !important;
    flex-direction: column-reverse;
    padding: 0rem 2rem;
    gap: 0 !important;
  }
  .content_text {
    margin-top: 2rem;
  }
  .content_text p {
    text-align: left;
  }
}

/* ========================== */
/* ========================== */
@media screen and (max-width: 1200px) {
  .missionvision {
    padding: 5rem 2rem !important;
  }
}

@media screen and (max-width: 700px) {
  .missionvision {
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .missionvision {
    padding: 5rem 1rem !important;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 700px) {
  .content_text {
    flex-direction: column;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 1100px) {
  .emicalculator .content {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .emicalculator .content .fields {
    display: flex !important;
    justify-content: space-between;
  }
  .emicalculator .content .fields .pie {
    width: 400px !important;
  }
}

@media screen and (max-width: 980px) {
  .emicalculator {
    display: flex !important;
  }
  .emicalculator .content .fields {
    display: flex !important;
    flex-direction: column;
    width: 80%;
  }
  .emicalculator .content .fields .pie {
    width: 400px !important;
  }
}

@media screen and (max-width: 700px) {
  .emicalculator .content {
    padding: 0 !important;
  }
  .emicalculator .content .fields {
    width: 95%;
  }
  .emicalculator .content .fields .cal-area .calculate {
    display: none;
  }
  .emicalculator .content .fields .pie {
    width: 300px !important;
  }
}

@media screen and (max-width: 350px) {
  .emicalculator .content {
    padding: 0 !important;
  }
  .emicalculator .content .fields .pie {
    width: 200px !important;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 710px) {
  .partner-wrapper .partners {
    flex-direction: column;
  }
  .partner-wrapper .partners .partner {
    flex-wrap: wrap;
  }
  .partner-wrapper .partners .partner article {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 440px) {
  .partner-wrapper .partners .partner {
    padding: 0 !important;
  }
  .partner-wrapper .partners .partner article {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
  }
  .partner-wrapper .partners .partner article img {
    object-fit: contain;
    border-radius: 0;
    width: 100%;
    margin: 0 !important;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 1200px) {
  .events-wrapper {
    padding: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .events-wrapper {
    padding: 4rem;
  }
  .events-wrapper .event {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
}

@media screen and (max-width: 630px) {
  .events-wrapper {
    padding: 1rem;
  }
}

@media screen and (max-width: 570px) {
  .events-wrapper .event {
    grid-template-columns: 1fr !important;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 670px) {
  .contact-wrapper .social-links {
    display: none !important;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 1200px) {
  .footer-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .copyright {
    padding: 2rem 1rem;
  }
}

/* ========================== */
/* ========================== */
/* ========================== */
/* ========================== */
@media screen and (max-width: 700px) {
  .messages {
    padding: 4rem 5% !important;
  }
}

@media screen and (max-width: 350px) {
  .messages {
    padding: 4rem 5px !important;
  }
}

/* ========================== */
/* ========================== */
.deposit-wrapper {
  background: #002b71;
  padding-bottom: 3rem;
}

.deposit-wrapper h2 {
  color: #fff;
  text-align: center;
  padding-top: 3rem;
  font-size: 2.5rem;
  font-weight: normal;
}

.deposit-wrapper .eventspage {
  max-width: 1140px;
  margin: 3rem auto 0 auto;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #ed4611;
}

.deposit-wrapper .eventspage .child-saving {
  padding: 1rem;
  margin: 2rem 1rem;
}

.deposit-wrapper .eventspage .child-saving h3 {
  font-size: 1.5rem;
  color: green;
  text-align: left;
}

.deposit-wrapper .eventspage .child-saving .content {
  margin: 2rem 0;
}

.deposit-wrapper .eventspage .child-saving .content ul {
  text-align: left;
}

.deposit-wrapper .eventspage .child-saving .content ul li {
  margin: 1rem 1.5rem;
}

/* single events page scss */
/* single events page scss */
.messages {
  background-color: #0c4fbb;
  color: #fff;
  display: flex;
  padding: 4rem 12%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.messages img {
  height: 200px;
  border-radius: 5px;
}

.messages h1 {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.5rem;
  margin: 20px 0;
}

.messages p {
  text-align: justify;
  line-height: 2rem;
}

.messages .message {
  border: 0.1px solid #bfbdbd;
  box-shadow: 0px 0px 25px 5px #002b71;
  padding: 2rem;
  border-radius: 10px;
}
