.emicalculator {
	background: #f8f8f8;
	border: 1px solid #f8f8f8;
	.content {
		width: 1140px;
		margin: 3rem auto;

		h2 {
			font-size: 2rem;
			font-weight: 400;
			color: $hl-color;
			text-transform: uppercase;
		}

		p {
			color: $primary-color;
			display: flex;
			gap: 1rem;
			justify-content: center;
		}

		.fields {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
			justify-content: space-between;
			margin: 2rem 0;
			.cal-area {
				margin: 3rem 0 4rem 0;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 1rem;
				width: 100%;
				padding: 1rem;
				.inputfield {
					width: 100%;
					display: grid;
					grid-template-columns: 8rem 1fr;
					align-items: center;
					gap: 1rem;

					label {
						font-size: 0.9rem;
						color: $text-color;
						// font-weight: 500;
						text-align: left;
						text-transform: uppercase;
					}
					input {
						width: 100%;
						padding: 0.5rem 0.8rem;
						border-radius: 5px;
						outline: none;
						border: 1px solid grey;

						&:focus {
							box-shadow: 0 0 7px rgb(106, 216, 247);
						}
					}
				}
				.calculate {
					// align-self: flex-end;
					border-radius: 15px;
					border: 1px solid $hl-color;
					display: flex;
					gap: 5px;
					// width: min-content;
					margin: 0 auto !important;
					// align-items: center;

					.icon {
						color: $hl-color;
					}

					padding: 0.7rem 1rem;

					button {
						// margin: 2rem 0;

						font-weight: 400;
						font-size: 0.9rem;
						text-transform: uppercase;
						background: transparent;
						border: none;
						color: $hl-color;
					}
				}
			}

			.pie {
				// border: 1px solid red;
				position: relative;
				width: 100%;
				// height: 300px;
			}
		}
	}
}
