.about {
	padding: 5rem 0;
	max-width: 1140px;
	// border: 1px solid red;
	margin: 0 auto;
	// background: $bg-white;

	h2 {
		text-transform: uppercase;
		font-weight: normal;
		font-size: 1.5rem;
		color: $hl-color;
	}

	.slogan {
		color: $primary-color;
		font-size: 1rem;
	}

	.collect {
		position: relative;
		align-items: center;
		.underline {
			border: 2px solid red;
			width: 80px;
			height: 80px;
			border-radius: 50%;
			margin: 1rem auto;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $hl-color;

			.welcomeicon {
				margin: 2rem auto;
				// position: absolute;
				font-size: 2.5rem;
				color: $bg-white;
			}
			.line {
				width: 70%;
				height: 5px;
				margin: 0 auto;
				background: $hl-color;
			}
		}
		hr {
			position: relative;
			bottom: 50px;
			z-index: -999;
			color: $hl-color;
			background: $hl-color;
			border: 0.2px solid $hl-color;
			width: 60%;
			margin: 0 auto;
		}
	}

	.content {
		margin-top: 4rem;
		display: grid;
		grid-template-columns: 60% 1fr;
		gap: 5rem;

		&_text {
			text-align: left;
			letter-spacing: 1px;
			line-height: 1.5rem;

			button {
				margin: 2rem 0;
				border: none;
				background: transparent;

				a {
					padding: 0.8rem 1.2rem;
					color: $hl-color;
					border-radius: 10px;
					outline: 1px solid $hl-color;
					text-decoration: none;
					display: flex;
					align-items: center;
					gap: 5px;
					font-weight: 500;
					.icon {
						font-size: 1rem;
					}

					&:hover {
						background: $hl-color;
						color: $bg-white;
					}
				}
			}
		}
		img {
			border-radius: 5px;
			width: 100%;
			object-fit: cover;
		}
	}
}
