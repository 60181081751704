.partner-wrapper {
	background: $hl-color;
	.partners {
		padding: 5rem 0;
		max-width: 1140px;
		// border: 1px solid red;
		margin: 0 auto;
		// background: $bg-white;

		h2 {
			text-transform: uppercase;
			font-weight: normal;
			font-size: 1.5rem;
			color: $bg-white;
		}

		.slogan {
			color: $bg-white;
			font-size: 1rem;
		}

		.collect {
			position: relative;
			align-items: center;
			.underline {
				width: 80px;
				height: 80px;
				border-radius: 50%;
				margin: 1rem auto;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba($bg-white, 1);
				z-index: 99999;
				.welcomeicon {
					margin: 2rem auto;
					// position: absolute;
					font-size: 2.5rem;
					color: $hl-color;
					z-index: 99999;
				}
				.line {
					width: 70%;
					height: 5px;
					margin: 0 auto;
					background: $hl-color;
				}
			}
			hr {
				position: relative;
				bottom: 50px;
				color: $bg-white;
				background: $bg-white;
				border: 0.2px solid $bg-white;
				width: 60%;
				margin: 0 auto;
			}
		}

		.partner {
			display: flex;
			align-items: center;
			justify-content: center;

			article {
				display: flex;

				img {
					height: 150px;
					background-color: rgba($bg-white, 1);
					padding: 0.2rem;
					border-radius: 10px;
					margin: 0 1rem;
				}
			}
		}
	}
}
