@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

*,
::before,
::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: "Roboto", sans-serif;
}

@import "./config";
@import "./header";
@import "./navbar";
@import "./news";
@import "./main";
@import "./slider";
@import "./popup";
@import "./about";
@import "./missionvision";
@import "./services";
@import "./home";
@import "./partners";
@import "./events";
@import "./contact";
@import "./footer";
@import "./eventspage";
@import "./reports";
@import "./profile";
@import "./vision";
@import "./boardMembers";
@import "./pie";
@import "./responsive";
@import "./remittance";
@import "./deposit";
@import "./messages";
