.popup {
	background: rgba($bg-white, 0.8);
	width: 100%;
	height: 100%;
	position: fixed;
	overflow-y: auto;
	top: 0;

	z-index: 99999999999 !important;
	&_content {
		max-width: 600px;
		overflow-y: auto;
		margin: auto;
		border: 2px solid #3e3e3e;
		background: #fdfcfc;
		padding: 0.5rem;
		position: relative;
		top: 4rem;

		.close {
			display: block;
			font-size: 1.5rem;
			font-weight: 500;
			text-align: right;
		}

		.message {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			padding: 2rem 0 10rem 0;
			overflow-y: auto;
			article {
				p {
					margin-top: 2rem;
					text-align: left;
					font-weight: 500;
					color: yellow;
					background-color: #3e3e3e;
					padding: 1rem;
				}

				img {
					width: 100%;
				}
			}
		}

		hr {
			display: block;
		}
	}
}
