.navbar {
	background: $primary-color;
	border-bottom: 3px solid $secodary-color;
	position: sticky;
	top: 0;
	z-index: 999;

	&_component {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 1140px;
		margin: 0 auto;
		position: relative;

		ul {
			display: flex;
			align-items: center;
			padding: 0;
			// transform: translateX(0%) !important;
			li {
				list-style-type: none;
				position: relative;

				.icon {
					font-size: 1rem;
				}

				a {
					background: $bg-white;
					padding: 0.4rem 1rem;
					margin: 0 0.2rem;
					text-decoration: none;
					display: flex;
					align-items: center;
					gap: 5px;
					border-radius: 5px;
					color: $text-color;
					font-size: 0.8rem;
					font-weight: 500;
					transition: all 0.3s linear;

					.sublinks {
						display: none;
					}
					&:hover {
						background: $hl-color;
						color: $bg-white;
						position: relative;
						.sublinks {
							display: flex;
							flex-direction: column;

							position: absolute;
							top: 100%;
							z-index: 9999;
							background-color: $primary-color;
							padding-bottom: 0;
							// min-width: 130px;
							border-radius: 5px !important;
							transform: translateX(-10px);
							overflow: hidden;
							&_list {
								width: 100%;
								padding: 0 !important;
								text-align: center;

								.sublinks_item {
									display: flex;
									color: $bg-white;
									text-transform: capitalize;

									background: $primary-color;

									border-bottom: 1px solid rgba($bg-white, 0.3);
									padding: 0.6rem 0.8rem;
									min-width: max-content;
									width: 100%;
									font-weight: normal;

									&:hover {
										background: $hl-color;
									}
								}
							}
						}
					}
				}

				.active_link {
					background: $hl-color;
					color: $bg-white;
				}
			}
		}

		ul {
			display: flex;
			align-items: center;
			padding: 1rem 0;
			li {
				list-style-type: none;
				position: relative;

				.icon {
					font-size: 1rem;
				}

				a {
					background: $bg-white;
					padding: 0.4rem 1rem;
					margin: 0 0.2rem;
					text-decoration: none;
					display: flex;
					align-items: center;
					gap: 5px;
					border-radius: 5px;
					color: $text-color;
					font-size: 0.8rem;
					font-weight: 500;
					transition: all 0.3s linear;
				}
			}
		}

		button {
			border: none;
			background: $bg-white;
			padding: 0.4rem 1rem;
			margin: 0 0.2rem;
			text-decoration: none;
			display: flex;
			align-items: center;
			gap: 5px;
			border-radius: 5px;
			color: $text-color;
			font-size: 0.8rem;
			font-weight: 500;
			transition: all 0.3s linear;
			.sublinks {
				display: none;
			}
			&:hover {
				background: $hl-color;
				color: $bg-white;
				position: relative;
				.sublinks {
					display: flex;
					flex-direction: column;
					padding-bottom: 0;
					position: absolute;
					top: 100%;
					z-index: 9999;
					background-color: $primary-color;

					// min-width: 130px;
					border-radius: 5px !important;
					transform: translateX(-10px);
					overflow: hidden;
					&_list {
						width: 100%;
						padding: 0 !important;
						text-align: center;

						.sublinks_item {
							display: flex;
							color: $bg-white;
							text-transform: capitalize;

							background: $primary-color;

							border-bottom: 1px solid rgba($bg-white, 0.3);
							padding: 0.6rem 0.8rem;
							min-width: max-content;
							width: 100%;
							font-weight: normal;

							&:hover {
								background: $hl-color;
							}
						}
					}
				}
			}
		}
	}
}
