table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

table td,
table th {
	border: 1px solid #ddd;
	padding: 8px;
}

table tr:nth-child(even) {
	background-color: #f2f2f2;
}

table tr:hover {
	background-color: #ddd;
}

table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: $primary-color;
	color: white;
}

.blink_me {
	animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.blink_title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.new {
		color: $hl-color;
		font-size: 0.8rem;
		margin-right: 1rem;
	}
}
