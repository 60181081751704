.contact-wrapper {
	background: linear-gradient(
			to right,
			rgba($accent-color, 0.7),
			rgba($accent-color, 0.7)
		),
		url("../images/img-1.png");
	padding: 4rem 0;

	.contact {
		display: flex;
		flex-direction: column;
		.header {
			position: relative;
			width: 100%;
			display: flex;
			flex-direction: column;
			.title {
				font-size: 2.5rem;
				color: $bg-white;
				font-weight: 400;
				text-transform: uppercase;
			}

			.collect {
				position: relative;
				align-items: center;
				width: 100%;
				.underline {
					width: 80px;
					height: 80px;
					border-radius: 50%;
					margin: 1rem auto;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: rgba($bg-white, 1);
					z-index: 99999;

					.welcomeicon {
						margin: 2rem auto;
						// position: absolute;
						font-size: 2.5rem;
						color: $hl-color;
						z-index: 99999;
					}
				}
				hr {
					position: relative;
					bottom: 50px;
					color: $bg-white;
					background: $bg-white;
					border: 0.5px solid $bg-white;
					width: 60%;
					margin: 0 auto;
				}
			}
		}
		.main {
			max-width: 1140px;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;
			justify-content: space-between;
			background: none;
			width: 100%;
			position: relative;

			.content {
				position: absolute;
				right: 0;
				top: 1rem;
				padding: 1rem;
				color: $bg-white;
				background: $hl-color;
				border-radius: 10px;
				h3 {
					font-weight: 400;
					margin: 0.7rem 0;
					letter-spacing: 1px;
				}

				p {
					opacity: 0.7;
					font-weight: 300;
					display: flex;
					align-items: center;
					gap: 0.5rem;
					font-size: 1rem;
					a {
						display: flex;
						align-items: center;
						gap: 10px;
						color: rgba($bg-white, 1);
						text-decoration: none;
					}

					.icon_ {
						opacity: 1 !important;
						font-size: 1.5rem;
					}
				}
				.social-links {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 0.5rem;
					font-size: 2.5rem;
					margin: 1rem 0;
					a {
						text-decoration: none;
						color: $bg-white;

						.icon {
							border: 1px dotted $bg-white;
							padding: 5px;
							border-radius: 5px;
							transition: all 0.5s ease-in-out;
						}
						.icon:hover {
							color: $hl-color;
							background-color: $bg-white;
						}
					}
				}
			}
			.map {
				border-radius: 20px;
				overflow: hidden;
			}
		}
	}
}
