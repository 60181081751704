.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1140px;
	margin: 1rem auto;

	img {
		height: 120px;
	}

	.logo {
		display: flex;
		gap: 1rem;
		align-items: center;

		.title {
			font-size: 2.5rem;
			color: $primary-color;

			&_text {
				text-align: left;
				font-size: 1.125rem;
				font-weight: 600;

				.nepali {
					letter-spacing: 3px;
					color: $primary-color;
				}
				.underline {
					background-color: $primary-color;
					height: 2.4px;
					width: 95%;
				}
				.english {
					letter-spacing: -0.5px;
					color: $secodary-color;
				}
			}
		}
	}

	.connect {
		display: flex;
		gap: 1rem;
		text-align: left;
		&_link {
			display: flex;
			align-items: center;
			gap: 0.6rem;

			.icon {
				font-size: 2rem;
				color: $accent-color;
			}

			.text {
				border-left: 1px dashed rgba($text-color-light, 0.3);
				padding-left: 8px;
				h5 {
					color: $text-color;
				}
				p {
					color: $text-color-light;
					font-weight: 300;
				}
				.active {
					color: rgb(0, 0, 0);
				}
				.disabled {
					opacity: 0.7;
					word-break: break-all;
				}

				.isopen-closed {
					color: $hl-color !important;
					opacity: 1 !important;
				}
				.isopen-open {
					color: $primary-color !important;
					opacity: 1 !important;
				}
			}
		}
	}
}
