.footer-wrapper {
	background: $primary-color;
	padding: 3rem 0;
	position: relative;
	.footer {
		max-width: 1140px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 1.5rem;
		color: $bg-white;
		.info {
			.title {
				color: #d9fa53;
				text-transform: uppercase;
			}
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 0.5rem;
			img {
				max-width: 120px;
			}
			p {
				display: flex;
				gap: 0.5rem;
				// text-align: left;
			}
		}

		.aboutus,
		.downloads,
		.service {
			.title {
				// border: 1px solid red;
				padding-bottom: 1rem;
				border-bottom: 2px solid $hl-color;
				color: #d9fa53;
			}

			li {
				list-style-type: none;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 1rem;
				margin: 1rem auto;
				a {
					text-decoration: none;
					color: $bg-white;

					&:hover {
						color: #d9fa53;
						transform: scale(1.1);
					}
				}
			}
		}

		.aboutus {
			.title {
				// border: 5px solid yellow;
				text-align: left;
			}
		}
	}
}
.copyright {
	position: relative;
	bottom: 0;
	width: 100%;
	background: #262626;
	padding: 1rem 0;
	p {
		color: $bg-white;
		font-size: 1rem;
		opacity: 0.8;
		font-weight: 300;
		margin: 1rem 0;
	}
	span {
		font-size: 1rem;
		color: $bg-white;
		opacity: 0.7;
		font-weight: 400;
	}
}
