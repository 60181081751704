.deposit-wrapper {
	background: $accent-color;
	padding-bottom: 3rem;

	h2 {
		color: $bg-white;
		text-align: center;
		padding-top: 3rem;
		font-size: 2.5rem;
		font-weight: normal;
	}
	.eventspage {
		max-width: 1140px;
		margin: 3rem auto 0 auto;
		background: $bg-white;
		border-radius: 20px;
		border: 1px solid $hl-color;
		// box-shadow: 0 0 20px $hl-color;

		.child-saving {
			padding: 1rem;
			margin: 2rem 1rem;
			h3 {
				font-size: 1.5rem;
				color: green;
				text-align: left;
			}
			.content {
				margin: 2rem 0;
				ul {
					text-align: left;

					li {
						margin: 1rem 1.5rem;
					}
				}
			}
		}
	}
}

/* single events page scss */

/* single events page scss */
