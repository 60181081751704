.wrapper {
	background: rgba($hl-color, 1);
	.missionvision {
		max-width: 1140px;
		margin: 0 auto;
		border-radius: 15px;
		padding: 3rem 0;
		display: flex;
		align-items: center;
		gap: 1.5rem;

		.mission_vision {
			box-shadow: 0 0 100px rgba($text-color-light, 0.5);
			background: $hl-color;
			border-radius: 10px;
			padding: 2rem;
			transition: all 0.5s ease-in-out;
			border: 0.1px solid rgba($bg-white, 0.5);
			// max-width: 500px;
			.icon_ {
				font-size: 2rem;
				color: $bg-white;
				border: 1px solid $bg-white !important;
				width: 120px;
				height: 90px;
				border-radius: 20px;
				transition: all 0.5s ease-in-out;
			}
			h2 {
				font-weight: 300;
				font-size: 2.125rem;
				color: $bg-white;
				margin: 1rem;
			}

			p {
				color: $bg-white;
				line-height: 1.5rem;
				letter-spacing: 1px;
				// font-weight: 300;
			}
			button {
				margin: 1rem 0;
				border: none;
				border-radius: 10px;

				a {
					padding: 0.6rem 1rem;
					color: $hl-color;
					border-radius: 10px;
					text-decoration: none;
					display: flex;
					align-items: center;
					gap: 5px;
					font-weight: 500;
					border: 1px solid $bg-white !important;
					background: $hl-color;
					color: $bg-white;
					transition: all 0.5s ease-in-out;
					.icon {
						font-size: 1rem;
						color: $bg-white;
					}
				}
			}

			&:hover {
				background: rgba(#ef5929, 1);
				transform: scale(1.04);
				.icon_ {
					color: $hl-color;
					background: $bg_white;
					border-radius: 20px;
				}

				button {
					a {
						background: $bg-white !important;
						color: $hl-color;
						.icon {
							font-size: 1rem;
							color: $hl-color;
						}
					}
				}
			}
		}
	}
}
