.slider {
	max-width: 1140px;
	margin: 0 auto;
	position: relative;
	min-height: 550px;
	overflow: hidden;
	border-radius: 10px;
	border: 5px solid rgba($bg-white, 0.8);

	.images {
		.icon {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 1.5rem;
			width: 100%;
			height: 100%;
			color: rgba($bg-white, 0.6);
			padding: 0 1rem;
		}

		.prev {
		}

		.next {
		}

		article {
			position: absolute;

			width: 100%;
			height: 100%;
			/* opacity: 0; */
			transition: var(--transition);

			.my-image {
				width: 100%;
			}
		}
		article.activeSlide {
			opacity: 1;
			transform: translateX(0);
		}
		article.lastSlide {
			transform: translateX(-100%);
		}
		article.nextSlide {
			transform: translateX(100%);
		}
	}
}
