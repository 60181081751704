//header

@media screen and (max-width: 1141px) {
	.App {
		.header {
			padding: 1rem;
		}
	}
}
@media screen and (max-width: 1100px) {
	.App {
		.header {
			flex-direction: column;
			gap: 1.2rem;
			align-items: center;
			.logo {
			}
			.connect {
				flex-wrap: wrap;
			}
		}
	}
}
@media screen and (max-width: 626px) {
	.App {
		.header {
			flex-direction: column;
			gap: 1.2rem;
			align-items: center;
			.logo {
				flex-direction: column;
			}
		}
	}
}
@media screen and (max-width: 311px) {
	.App {
		.header {
			.logo {
				flex-direction: column;

				.nepali {
					letter-spacing: 2px;
					text-align: center;
				}
			}
		}
	}
}

/* end::header */
/* ========================== */
///* begin::navbar */
/* ========================== */
.bars {
	color: $bg-white;
	font-size: 1.5rem;
	display: none;
}
@media screen and (max-width: 1141px) {
	.App {
		.navbar {
			padding: 0 1rem;

			&_component {
				flex-wrap: wrap;
			}
		}
	}
}
@media screen and (max-width: 1007px) {
	.App {
		.navbar {
			&_component {
				flex-wrap: wrap;
				justify-content: center !important;
				align-items: center !important;

				.menu {
					width: 100%;
					padding: 0.5rem 0;
					// height: min-content !important;
					justify-content: center;
				}
				ul {
					padding: 0.5rem 0;
				}
			}
		}
	}
}
@media screen and (max-width: 895px) {
	.App {
		.navbar {
			position: relative;
			&_component {
				justify-content: space-between !important;
				align-items: center;
				position: relative;
				width: 100%;
				.bars {
					display: block;
					font-size: 1.7rem;
					transition: all 0.3s ease-in-out;
				}

				.menu {
					flex-direction: column;
					position: absolute;
					top: 100%;
					background: $bg-white;
					transition: all 0.4s ease-in-out;
					padding: 0;
					li {
						width: 100%;

						a {
							position: relative;
							left: 0%;
							margin: 0;
							border-radius: 0 !important;
							padding: 0.8rem 1.5rem;
							width: 100%;
							display: flex;
							.icon-last {
								// float: right;
								position: absolute;
								right: 1rem;
								// font-size: 2rem;
								display: block;
							}

							&:hover {
								.sublinks {
									right: 0%;
									width: 50%;
								}
							}
						}
					}
				}
			}
		}
	}
}
/* ========================== */
///* end::navbar */
/* ========================== */
/* ========================== */
///* begin::navbar news slider*/
/* ========================== */

@media screen and (max-width: 1141px) {
	.news {
		margin: 1rem 1rem;
	}
}
@media screen and (max-width: 420px) {
	.news {
		display: none;
	}
}

@media screen and (max-width: 828px) {
	.slider {
		min-height: 400px !important;
	}
}
@media screen and (max-width: 599px) {
	.slider {
		min-height: 300px !important;
	}
}
@media screen and (max-width: 447px) {
	.slider {
		min-height: 200px !important;
	}
}

/* ========================== */
///* end::navbar news slider*/
/* ========================== */
/* ========================== */
///* begin::about*/
/* ========================== */

/* ========================== */
///* end::about*/
/* ========================== */

@media screen and(max-width:1200px) {
	.about {
		padding: 5rem 1rem !important;
		// background: $bg-white;
	}
}
@media screen and(max-width:994px) {
	.content {
		display: flex !important;
		flex-direction: column-reverse;
		padding: 0rem 2rem;
		gap: 0 !important;
		&_text {
			margin-top: 2rem;
			p {
				text-align: left;
			}
		}
	}
}

/* ========================== */
///* begin:: missionvision*/
/* ========================== */

@media screen and (max-width: 1200px) {
	.missionvision {
		padding: 5rem 2rem !important;
	}
}
@media screen and (max-width: 700px) {
	.missionvision {
		flex-direction: column;
	}
}
@media screen and (max-width: 400px) {
	.missionvision {
		padding: 5rem 1rem !important;
	}
}

/* ========================== */
///* end:: missionvision*/
/* ========================== */
/* ========================== */
///* begin::our services*/
/* ========================== */
@media screen and (max-width: 700px) {
	.content_text {
		flex-direction: column;
	}
}
/* ========================== */
///* end::our services*/
/* ========================== */
/* ========================== */
///* begin:: emi calculator	*/
/* ========================== */

@media screen and (max-width: 1100px) {
	.emicalculator {
		.content {
			display: flex !important;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.fields {
				display: flex !important;
				justify-content: space-between;

				.pie {
					width: 400px !important;
				}
			}
		}
	}
}
@media screen and (max-width: 980px) {
	.emicalculator {
		display: flex !important;

		.content {
			.fields {
				display: flex !important;
				flex-direction: column;
				width: 80%;

				.pie {
					width: 400px !important;
				}
			}
		}
	}
}
@media screen and (max-width: 700px) {
	.emicalculator {
		.content {
			padding: 0 !important;
			.fields {
				width: 95%;
				.cal-area {
					.calculate {
						display: none;
					}
				}
				.pie {
					width: 300px !important;
				}
			}
		}
	}
}
@media screen and (max-width: 350px) {
	.emicalculator {
		.content {
			padding: 0 !important;
			.fields {
				.pie {
					width: 200px !important;
				}
			}
		}
	}
}

/* ========================== */
///* end:: emi calculator	*/
/* ========================== */
/* ========================== */
///* begin::remittance partners	*/
/* ========================== */

@media screen and (max-width: 710px) {
	.partner-wrapper {
		.partners {
			flex-direction: column;
			.partner {
				flex-wrap: wrap;
				article {
					margin-top: 1rem;
				}
			}
		}
	}
}
@media screen and (max-width: 440px) {
	.partner-wrapper {
		.partners {
			.partner {
				padding: 0 !important;
				article {
					padding: 0 !important;
					margin: 0 !important;
					width: 100%;

					img {
						object-fit: contain;
						border-radius: 0;
						width: 100%;
						margin: 0 !important;
					}
				}
			}
		}
	}
}
/* ========================== */
///* end::remittance partners*/
/* ========================== */
/* ========================== */
///* begin::events*/
/* ========================== */

@media screen and (max-width: 1200px) {
	.events-wrapper {
		padding: 2rem;
	}
}
@media screen and (max-width: 900px) {
	.events-wrapper {
		padding: 4rem;
		.event {
			display: grid !important;
			grid-template-columns: 1fr 1fr !important;
		}
	}
}
@media screen and (max-width: 630px) {
	.events-wrapper {
		padding: 1rem;
	}
}
@media screen and (max-width: 570px) {
	.events-wrapper {
		.event {
			grid-template-columns: 1fr !important;
		}
	}
}
/* ========================== */
///* end::events*/
/* ========================== */
/* ========================== */
///*begin: contacts*/
/* ========================== */
@media screen and (max-width: 670px) {
	.contact-wrapper {
		.social-links {
			display: none !important;
		}
	}
}
/* ========================== */
///* end::contacts*/
/* ========================== */
/* ========================== */
///* begin::footer*/
/* ========================== */
@media screen and (max-width: 1200px) {
	.footer-wrapper {
		display: none;
	}
}
@media screen and (max-width: 1200px) {
	.copyright {
		padding: 2rem 1rem;
	}
}
/* ========================== */
///* end::footer*/
/* ========================== */
/* ========================== */
///* begin::messages*/
/* ========================== */

@media screen and (max-width: 700px) {
	.messages {
		padding: 4rem 5% !important;
	}
}
@media screen and (max-width: 350px) {
	.messages {
		padding: 4rem 5px !important;
	}
}
/* ========================== */
///* end::messages*/
/* ========================== */
