.vision {
	padding: 2rem 1rem;

	h4 {
		font-size: 2rem;
		font-weight: normal;
		margin: 1rem 0 2rem 0;
	}
	.vision-content {
		p {
			padding: 1rem;
			line-height: 1.5rem;
			letter-spacing: 0.7px;
			word-spacing: 2px;
		}

		.icon {
			color: $hl-color;
		}
	}
}
