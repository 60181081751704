$primary-color: #018030;
$secodary-color: #e32e31;
$accent-color: #002b71;
$accent-color-light: #0c4fbb;
$hl-color: #ed4611;
$text-color: #000;

$text-color-light: #3a3737;
$bg-white: #fff;

@mixin lg {
	@media screen and (max-width: 1140px) {
		@content;
	}
}
@mixin md {
	@media screen and (max-width: 1140px) {
		@content;
	}
}
@mixin sm {
	@media screen and (max-width: 1140px) {
		@content;
	}
}
