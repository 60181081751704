.eventspage-wrapper {
	background: $accent-color;
	padding-bottom: 3rem;

	h2 {
		color: $bg-white;
		text-align: center;
		padding-top: 3rem;
		font-size: 2.5rem;
		font-weight: normal;
	}
	.eventspage {
		max-width: 1140px;
		margin: 3rem auto 0 auto;
		background: $bg-white;
		border-radius: 20px;
		border: 1px solid $hl-color;
		// box-shadow: 0 0 20px $hl-color;

		ul {
			display: flex;
			flex-direction: column;
			text-align: left;
			padding: 3rem;
			gap: 1rem;
			.title_single {
				font-size: 2rem;
				font-weight: normal;
				color: $primary-color;
			}
			.date_single {
				font-size: 0.9rem;
				opacity: 0.8;
			}
			.description_single {
				margin: 1rem 0;
				line-height: 1.5rem;
				letter-spacing: 1px;
			}
			.image-grid {
				display: grid;
				grid-template-columns: repeat(auto-fill, 195px);
				// justify-content: flex-start;
				gap: 1rem;
				margin-top: 5rem;

				img {
					width: 100%;

					height: 200px;
					border-radius: 5px;
					object-fit: contain;
					// border: 0.5px solid $hl-color;
					box-shadow: 0 0 5px $hl-color;
				}
			}
			li {
				transition: all 0.3s linear;
				&:hover {
					background: rgba($text-color-light, 0.2);
					transform: scale(1.01);
				}
				display: grid;
				grid-template-columns: 16rem 1fr;
				border: 1px solid rgba($text-color-light, 0.2);
				// box-shadow: 0 0px 10px $text-color-light;
				border-radius: 10px;

				img {
					width: 100%;
					height: 200px;
					object-fit: cover;
					object-position: top;
					border-radius: 5px;
				}

				.content {
					text-align: left;
					padding: 0 1.5rem;

					.title {
						margin: 1rem 0;
						font-size: 1.3rem;
						font-weight: normal;
						color: $accent-color;
					}
					.date {
						font-size: 0.8rem;
						span {
							color: $primary-color;
						}
						color: $text-color-light;
					}
					p {
						margin: 0.5rem;
						letter-spacing: 1.5px;
					}
					button {
						margin: 1rem 0;
						border: none;
						background: transparent;

						a {
							padding: 0.6rem 1rem;
							color: $hl-color;
							border-radius: 10px;
							outline: 1px solid $hl-color;
							text-decoration: none;
							display: flex;
							align-items: center;
							gap: 5px;
							font-weight: 500;
							font-size: 0.8rem;
							.icon {
								font-size: 1rem;
							}

							&:hover {
								background: $hl-color;
								color: $bg-white;
							}
						}
					}
				}
			}
		}
	}
}

/* single events page scss */
