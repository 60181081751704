.messages {
	background-color: $accent-color-light;
	color: #fff;
	display: flex;
	padding: 4rem 12%;
	justify-content: space-evenly;
	flex-wrap: wrap;
	gap: 1.5rem;

	img {
		height: 200px;
		border-radius: 5px;
	}

	h1 {
		text-transform: capitalize;
		font-weight: 400;
		font-size: 1.5rem;
		margin: 20px 0;
	}

	p {
		text-align: justify;
		line-height: 2rem;
	}

	.message {
		border: 0.1px solid rgb(191, 189, 189);
		box-shadow: 0px 0px 25px 5px $accent-color;
		padding: 2rem;
		border-radius: 10px;
	}
}
