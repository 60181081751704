.news {
	max-width: 1140px;
	margin: 1rem auto;
	display: grid;
	grid-template-columns: 120px 1fr;

	align-items: center;
	z-index: -99;

	h2 {
		text-transform: capitalize;
		font-size: 1.1rem;
		padding: 0.5rem;
		font-weight: 400;
		background: $secodary-color;
		color: $bg-white;
		border-radius: 5px 0 0 5px;
	}
	.wrapper {
		overflow: hidden;
		background-color: $bg-white;
		width: 100%;
		height: 100%;
		border-radius: 0px 5px 5px 0;
		display: flex;
		.news_title {
			display: flex;
			align-items: center;
			gap: 1.5rem;

			transform: translateX(100%);
			animation: scrolling 50s linear infinite;

			&:hover {
				animation-play-state: paused;
			}

			li {
				position: relative;
				width: max-content !important;
				list-style-type: circle;
				a {
					text-decoration: none;
					padding: 0 5px;
					color: $accent-color-light;
					font-size: 14px;

					&:hover {
						color: $primary-color;
					}
				}
			}
		}
	}
}

@keyframes scrolling {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}
