.events-wrapper {
	background: $bg-white;
	.events {
		padding: 5rem 0;
		max-width: 1140px;
		// border: 1px solid red;
		margin: 0 auto;
		// background: $bg-white;

		h2 {
			text-transform: uppercase;
			font-weight: normal;
			font-size: 1.5rem;
			color: $hl-color;
		}

		.slogan {
			color: $primary-color;
			font-size: 1rem;
		}

		.collect {
			position: relative;
			align-items: center;
			.underline {
				width: 80px;
				height: 80px;
				border-radius: 50%;
				margin: 1rem auto;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: rgba($hl-color, 1);
				z-index: 99999;
				.welcomeicon {
					margin: 2rem auto;
					// position: absolute;
					font-size: 2.5rem;
					color: $bg-white;
					z-index: 99999;
				}
				.line {
					width: 70%;
					height: 5px;
					margin: 0 auto;
					background: $hl-color;
				}
			}
			hr {
				position: relative;
				bottom: 50px;
				color: $hl-color;
				background: $hl-color;
				border: 0.2px solid $hl-color;
				width: 60%;
				margin: 0 auto;
			}
		}

		.event {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			align-items: flex-start;
			justify-content: center;
			gap: 1rem;
			article {
				display: flex;
				flex-direction: column;
				align-items: center;
				border: 1px solid $hl-color;
				border-radius: 10px;
				box-shadow: 0 0 10px $hl-color;
				padding: 0 1rem;
				transition: all 0.3s ease-in-out;

				img {
					width: 100%;
					height: 200px;
					object-fit: cover;
					background-color: rgba($bg-white, 1);
					padding: 0.2rem;
					border-radius: 10px;
					margin: 0 1rem;
					transition: all 0.3s ease-in-out;
				}
				h4 {
					color: $primary-color;
					font-weight: normal;
					margin: 0.3rem;
					font-size: 0.9rem;
					text-transform: capitalize;
					transition: all 0.3s ease-in-out;
				}
				.date {
					font-size: 0.8rem;
					margin: 0.3rem 0;
				}
				p {
					text-align: justify;
					font-weight: 300;
					font-size: 0.9rem;
				}
				button {
					margin: 2rem 0;
					border: none;
					background: transparent;
					width: max-content;

					a {
						padding: 0.6rem 1rem;
						color: $hl-color;
						border-radius: 10px;
						outline: 1px solid $hl-color;
						text-decoration: none;
						display: flex;
						align-items: center;
						gap: 5px;
						font-weight: 500;
						font-size: 0.7rem;
						.icon {
							font-size: 1rem;
						}

						&:hover {
							background: $hl-color;
							color: $bg-white;
						}
					}
				}

				&:hover {
					transform: scale(1.05);
					h4 {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}
