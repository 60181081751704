.boardmembers-content {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	.samiti {
		display: flex;
		flex-direction: column;
		width: 100%;
		border: 1px dashed $hl-color;
		padding: 2rem;
		gap: 1rem;

		li {
			list-style-type: none;
			margin: 1rem auto;
			align-items: baseline;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
			img {
				margin: 0.5rem auto;
				width: 200px;
				height: 250px;
				border-radius: 10px;
				// border: 1px solid $text-color;
				// box-shadow: 0 0 15px $text-color;
			}
		}
		h3 {
			padding: 1rem;
			font-size: 1.5rem;
			font-weight: normal;
			color: $hl-color;
		}

		.members {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
		}
	}
}
